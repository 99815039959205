<template>
  <b-modal
    id="image_modal"
    :size="modalSize"
    @show="onShow"
    hide-header
    hide-footer
    centered
    body-class="text-center"
  >
    <div>
      <vue-image-zoomer
        :regular="image"
        :zoom-amount="3"
        :click-zoom="true"
        img-class="img-fluid"
      />
    </div>
  </b-modal>
</template>

<script>
import store from "@/store";
import VueImageZoomer from 'vue-image-zoomer';

export default {
  components: {
    VueImageZoomer,
  },
  data() {
    return {
      image: '',
      modalSize: null,
    };
  },
  computed: {
    getImage() {
      return store.state.image_modal.url;
    },
  },
  methods: {
    onShow() {
      this.image = this.getImage;
      let img = new Image();
      img.src = this.image;

      img.onload = () => {
        if(img.height > window.innerHeight || img.width > window.innerWidth){
          this.modalSize = 'xl';
          let modal =  document.querySelector(".modal-dialog-centered");
          modal.setAttribute("style", "max-width: 75%; margin: auto;");
        }
        else{
          this.modalSize = 'lg';
        }
      }
    }
  },
};
</script>
